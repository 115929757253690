// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
  $('#wrapper').addClass('setTouch');
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
// $(function () {
//   // setScroll();
//   // if ($('#wrapper').hasClass('home')) {
//   //   setWave();
//   // }
//   // ///////////
//   // if (!_ua.Mobile && !_ua.Tablet) {
//   //   $('#wrapper').addClass('desktop');
//   //   if (!$('#wrapper').hasClass('about')) {
//   //     $('html, body').prop({
//   //       scrollTop: 0,
//   //     });
//   //     if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
//   //       // ここにSafari用の記述
//   //     } else {
//   //     }
//   //   }
//   // }
//   // ///////////
// });

//リロード時のスクロール位置復元をOFFに;
// window.addEventListener('beforeunload', function () {
//   window.scrollTo(0, 0);
// });

$(window).on('load', function () {
  judgeWinSize();
  setHeader();
  setSpan();
  // setInview();
  setAcc();
  // setMegaMenu();
  startScroll();
  // setMainMenu();
  setLoaded();
  setWay();
  setRellax();
  mailTo();
  heightLineGroup();
  setMainMenu();
  setSlider_01();
  setScrollHint();
  setTimeout(() => {
    setKvGsap();
    ScrollTrigger.refresh();
  }, 100); // 100ミリ秒の遅延
  // setBlobsSlide(6000);
});

var current_scrollY = 0;

//KV gSap
function setKvGsap() {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.matchMedia({
    '(min-height: 601px) and (min-width: 769px)': function () {
      gsap.to('#scrub_01', {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: '.js-scrub_01',
          start: 'top 80%',
          end: 'top 50%',
          // markers: true,
          scrub: 0.8,
          // scrub: true,
          // toggleClass: {
          //   targets: '.kvSec_01 .content', // クラスを切り替える対象の要素
          //   className: 'is-active', // クラス名 "active" を切り替える
          // },
          // toggleActions: 'play none none reverse',
        },
      });

      gsap.to('#scrub_02', {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: '.js-scrub_02',
          start: 'top 80%',
          end: 'top 50%',
          // markers: true,
          scrub: 0.8,
        },
      });

      gsap.to('.js-scrub-parent_01', {
        opacity: 0,
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: '.js-scrub_02',
          start: 'top 80%',
          end: 'top 50%',
          // markers: true,
          scrub: 0.8,
        },
      });
    },
  });
  gsap.to('#container', {
    scrollTrigger: {
      invalidateOnRefresh: true,
      trigger: '#container',
      start: 'top 10%', // ビューポートの上部から20%の位置でトリガー
      onEnter: () => {
        document.querySelector('#wrapper').classList.add('switchHeader');
      },
      onLeaveBack: () => {
        document.querySelector('#wrapper').classList.remove('switchHeader');
      },
      // markers: true, // デバッグ用のマーカーを表示
    },
  });
}

//スクロールヒント
function setScrollHint() {
  $('.scrollHintSec').scroll(function () {
    $(this).parent().find('.scrollHintIcon').fadeOut();
    // スクロールしたときの処理内容を記述する
  });
  // new ScrollHint('.js-scrollHint', {
  //   i18n: {
  //     scrollable: 'スクロールできます',
  //   },
  // });
}

//fancyBox
function setFancy() {
  $('.setFancy').fancybox({
    // オプションを下記に記載していきます
    // animationEffect: 'zoom-in-out', //アニメーションをズームインアウトに
    // loop: true, // 複数画像表示時に最初と最後をループさせる
    toolbar: false, // ツールバーの非表示
    // smallBtn: true, // 小さいCloseボタン利用する
    // iframe: {
    //   // iframe 関連の処理
    //   preload: false, //iframeのプリロードをしない
    // },
  });
}

//メアドスパム対策
function mailTo() {
  $('.mailTo').click(function () {
    var address = $(this).attr('href').replace('+', '@');
    location.href = 'mailto:' + address;
    return false;
  });
}

// Rellax
function setRellax() {
  var rellax = new Rellax('.js-rellax');
}

// カルーセル
function setSlider_01() {
  const mySwiperMain = new Swiper('.carouselStyle_01 .swiper-main', {
    slidesPerView: 1,
    spaceBetween: 0,
    watchSlidesProgress: true,
    loop: true,
    effect: 'fade',
    // preventClicks: false,
    // preventClicksPropagation: false,
    // autoplay: {
    //   // 自動再生
    //   delay: 8000, // 1.5秒後に次のスライド
    //   disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
    // },
    mousewheel: {
      forceToAxis: true,
    },
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   draggable: true,
    // },
    navigation: {
      nextEl: '.custom-swiper-button-next',
      prevEl: '.custom-swiper-button-prev',
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true, // クリック有効化
    //   type: 'fraction',
    // },
    speed: 500, // スライドアニメーションのスピード（ミリ秒）
    // breakpoints: {
    //   769: {
    //     slidesPerView: 5,
    //     spaceBetween: 36,
    //   },
    //   // 1000: {
    //   //   slidesPerView: 3,
    //   //   spaceBetween: 30,
    //   // },
    // },
  });
}

// //////////////////////////メインメニュー
function setMainMenu() {
  $('.mainMenuBtn').on('click', function () {
    if (!$('.mainMenuBtn').hasClass('active')) {
      openFnc();
    } else {
      closeFnc();
    }
  });
  $('.menuOpen #clickBlocker,.menuCloseArea').on('click', function (event) {
    event.stopPropagation();
    closeFnc();
  });

  // $('.mainNav a').on('click', function (event) {
  //   event.stopPropagation();
  // });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearInterval(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}

function openFnc() {
  current_scrollY = $(window).scrollTop();
  $('.mainMenuBtn').addClass('active');
  $('#wrapper').addClass('menuOpen');
  $('.headReserveBtn').removeClass('is-active');
  $('.headSearch').slideUp('fast');
  $('#wrapper').removeClass('searchOpen');
  $('.headSearchNav li.tab_00').click();
  if ($('#wrapper').hasClass('setSp')) {
    $('#outerMenu').css('top', -current_scrollY);
  }
}

function closeFnc() {
  $('.mainMenuBtn').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  if ($('#wrapper').hasClass('setSp')) {
    $('html, body').prop({
      scrollTop: current_scrollY,
    });
  }
}

// ///////ヘッダー制御
let set_position = 0;
function setHeader() {
  var timer = false;
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('#wrapper').removeClass('fixedHeader');
      $('#wrapper').removeClass('fixedHeaderDelay');
    }, 10);
  });

  fixedHeader();
  // console.log($('#mainVis').height())

  $(window).scroll(function () {
    fixedHeader();
  });

  function fixedHeader() {
    h = $('.blank').height();
    baseHeight = h;
    if ($(this).scrollTop() < baseHeight) {
      $('#wrapper').removeClass('fixedHeader');
    } else if ($(this).scrollTop() >= baseHeight) {
      $('#wrapper').addClass('fixedHeader');
    }
    if (set_position < $(this).scrollTop()) {
      $('#wrapper').addClass('scrollDown');
      $('#wrapper').removeClass('scrollUp');
    } else {
      $('#wrapper').addClass('scrollUp');
      $('#wrapper').removeClass('scrollDown');
    }

    set_position = $(this).scrollTop();
  }
}

// テキストアニメーション用span生成
function setSpan() {
  $('.txEffect>span').each(function () {
    var text = $.trim(this.textContent),
      html = '';

    text.split('').forEach(function (v) {
      html += '<span>' + v + '</span>';
    });

    this.innerHTML = html;
  });
}

// ///////waypoint
function setWay() {
  $('.way,.way-slow,.alphaWay,.js-way,.autoWay').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.sp-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active-sp');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-sp-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.js-item-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active-item');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-item-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.video-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      var videoElem = activePoint.find('video');
      if (direction === 'down') {
        videoElem.get(0).play();
      }
    },
    {
      offset: '70%',
    }
  );
}

// ////////////////////////////アコーディオン
function setAcc() {
  $('.js-toggle .head').on('click', function () {
    $(this).parent().toggleClass('active');
    $(this).next().stop().slideToggle(600);
  });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper,#loading').addClass('loaded');
  loadStart();
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedDone');
  }, 200);
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedStart');
  }, 600);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
    $('#mainVis').addClass('is-active');
  }, 1500);
  timer = setTimeout(function () {
    $('#wrapper').addClass('homeStart');
  }, 1200);
  if ($('#wrapper').hasClass('home')) {
    timer = setTimeout(function () {
      $('#wrapper').addClass('homeDelay');
    }, 2200);
  } else {
    timer = setTimeout(function () {
      $('#wrapper').addClass('pagesDelay');
    }, 500);
  }
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  var headerHeight = $('header').height();
  // var headerHeight = $('header .col-main').height() + 50;
  // if ($('#wrapper').hasClass('setPc')) {
  //   var headerHeight = 0;
  // } else {
  //   var headerHeight = 100;
  // }
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    // var position = target.offset().top;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    setTimeout(function () {
      var position = target.offset().top - headerHeight;
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      );
    }, 100);
    e.preventDefault();
    // $.when(
    //   $('html, body').animate(
    //     {
    //       scrollTop: position,
    //     },
    //     400,
    //     'swing'
    //   ),
    //   e.preventDefault()
    // ).done(function () {
    //   var diff = target.offset().top;
    //   if (diff === position) {
    //   } else {
    //     $('html, body').animate(
    //       {
    //         scrollTop: diff,
    //       },
    //       400,
    //       'swing'
    //     );
    //   }
    // });
  });
}

function startScroll() {
  // ページのURLを取得
  const url = $(location).attr('href'),
    // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
    headerHeight = $('header').outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf('#') != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split('#'),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $('html, body').animate({ scrollTop: position }, 500);
  }
  setScroll();
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

function watchViewMode() {
  window.addEventListener('orientationchange', function () {
    // orientationchange イベントがトリガーされたときの処理をここに記述します
    location.reload(); // ページをリロードします
  });
}
// //////////////////////////////高さ揃え
function heightLineGroup() {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    setAutoHeight('.staffCont dl:nth-child(1)', 4);
    setAutoHeight('.staffCont dl:nth-child(2)', 4);
    setAutoHeight('.staffCont dl:nth-child(3)', 4);
  }
  setAutoHeight('.setH_04', 2);

  startResize();
}

function setAutoHeight(target, count) {
  $(target).tile(count);
}

function startResize() {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
